import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

import { formatDateToDMYT } from '@core/utils/filter';
import StaticOptions from '@/common/options/StaticOptions';

export function fetchTicketsAll() {
  store.dispatch('ticketYonetim/fetchTickets', { self: 0, isActive: true })
}

export function fetchMyTickets() {
  store.dispatch('ticketYonetim/fetchTickets', { self: 1, isActive: true })
}

export function fetchClosedTickets() {
  store.dispatch('ticketYonetim/fetchTickets', { self: 2, isActive: false })
}

export function assignTicket(ticketId) {
  return new Promise((resolve, reject) => {
    store.dispatch('ticketYonetim/assignTicket', ticketId)
    .then(response => resolve(response))
    .catch(error => reject(error))
  })
}

export function fetchAssignedTickets(ticketId) {
  return new Promise((resolve, reject) => {
    store.dispatch('ticketYonetim/fetchAssignedTickets', ticketId)
    .then(response => resolve(response))
    .catch(error => reject(error))  
  })
}

export default function useTickets() {
  const ticketsTable = ref(null)
  const myTicketsTable = ref(null)
  const closedTicketsTable = ref(null)
  
  const tableColumns = [
    { key: 'sKayitNo', label: 'Kayıt No' },
    { key: 'sKonu', label: 'Konu' },
    { key: 'departmentTuru', label: 'Departman' },
    { key: 'ticketOncelikTuru', label: 'Öncelik', sortable: true },
    { key: 'ticketUygulamaTuru', label: 'Uygulama', sortable: true },
    { key: 'ilgiliPersonelAd', label: 'Son Atanan' },
    { key: 'dtOlusTarih', label: 'Ekleme Tarihi', sortable: true },
    { key: 'actions', label: 'Aksiyonlar' },
  ]
  const perPage = ref(10)
  const perMyPage = ref(10)
  const perClosedPage = ref(10)
  const toplamTicket = ref(0)
  const toplamMyTicket = ref(0)
  const toplamClosedTicket = ref(0)
  const currentPage = ref(1)
  const currentMyPage = ref(1)
  const currentClosedPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters['ticketYonetim/getTicketFilter'].sortBy
    },
    set(value) {
      store.commit('setTicketFilter', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters['ticketYonetim/getTicketFilter'].sortDesc
    },
    set(value) {
      store.commit('setTicketFilter', { key: 'sortDesc', value })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = ticketsTable.value ? ticketsTable.value.localItems.length : 0
    return {
      from: currentPage.value === 1 ? 1 : perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value) < localItemsCount ? perPage.value * (currentPage.value) : localItemsCount,
      of: toplamTicket.value,
    }
  })

  const myDataMeta = computed(() => {
    const localItemsCount = myTicketsTable.value ? myTicketsTable.value.localItems.length : 0
    return {
      from: currentMyPage.value === 1 ? 1 : perMyPage.value * (currentMyPage.value - 1) + 1,
      to: perMyPage.value * (currentMyPage.value) < localItemsCount ? perMyPage.value * (currentMyPage.value) : localItemsCount,
      of: toplamMyTicket.value,
    }
  })

  const closedDataMeta = computed(() => {
    const localItemsCount = closedTicketsTable.value ? closedTicketsTable.value.localItems.length : 0
    return {
      from: currentClosedPage.value === 1 ? 1 : perClosedPage.value * (currentClosedPage.value - 1) + 1,
      to: perClosedPage.value * (currentClosedPage.value) < localItemsCount ? perClosedPage.value * (currentClosedPage.value) : localItemsCount,
      of: toplamClosedTicket.value,
    }
  })

  const refetchData = () => {
    ticketsTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const refetchMyData = () => {
    myTicketsTable.value.refresh()
  }

  watch([currentMyPage, perMyPage], () => {
    refetchMyData()
  })

  const refetchClosedData = () => {
    closedTicketsTable.value.refresh()
  }

  watch([currentClosedPage, perClosedPage], () => {
    refetchClosedData()
  })

  const getTickets = computed(() => {
    const tickets = store.getters['ticketYonetim/getTickets']
    toplamTicket.value = tickets.length
    return tickets
  })

  const getMyTickets = computed(() => {
    const tickets = store.getters['ticketYonetim/getMyTickets']
    toplamMyTicket.value = tickets.length
    return tickets
  })
  
  const getClosedTickets = computed(() => {
    const tickets = store.getters['ticketYonetim/getClosedTickets']
    toplamClosedTicket.value = tickets.length
    return tickets
  })

  onMounted(fetchTicketsAll)
  
  onMounted(fetchMyTickets)
  
  onMounted(fetchClosedTickets)

  function resolveDepartmanTur(tur) {
    const result = StaticOptions.departmentTuruOptions.find(x => x.value === tur);
    return result ? result.label : ''
  }

  function resolveTicketUygulamaTur(tur) {
    const result = StaticOptions.ticketUygulamaTuruOptions.find(x => x.value === tur);
    return result ? result.label : ''
  }

  function resolveOncelikTur(tur) {
    return tur === 1 ? 'Acil' : 'Normal'
  }

  return {
    fetchTicketsAll,
    fetchMyTickets,
    fetchClosedTickets,
    getTickets,
    getClosedTickets,
    getMyTickets,
    tableColumns,
    perClosedPage,
    currentClosedPage,
    toplamClosedTicket,
    perPage,
    perMyPage,
    currentPage,
    currentMyPage,
    toplamTicket,
    toplamMyTicket,
    dataMeta,
    myDataMeta,
    closedDataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    ticketsTable,
    myTicketsTable,
    closedTicketsTable,
    assignTicket,
    fetchAssignedTickets,
    resolveDepartmanTur,
    resolveTicketUygulamaTur,
    resolveOncelikTur,

    formatDateToDMYT,
  }
}
