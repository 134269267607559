<template>
  <b-row>
    <b-col lg="12" md="12">
      <b-card
          v-if="ticketDetayList[0]"
      >
        <b-card v-if="!ticketDetayList.length">
          <b-row>
            <b-col>
              <div class="d-flex">
                <feather-icon icon="XIcon" size="19" class="text-warning" />
                <h6 class="mb-0 ml-50 text-warning">
                  {{ $t('Destek detayı bulunamadı') }} 
                </h6>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          :key="detay.id"
          v-for="detay in ticketDetayList"
          border-variant="primary"
        >
          <b-row v-b-toggle="`collapse-${detay.id}`">
            <b-col>
              <div class="d-flex">
                <feather-icon icon="UserIcon" size="19" class="text-primary" />
                <h6 class="mb-0 ml-50 text-primary">
                  {{ detay.isim }}
                </h6>
              </div>
            </b-col>
            <b-col>
              <span class="float-right">
                <feather-icon icon="ClockIcon" class="mr-50" />
                {{ formatDateToDMYT(detay.dtOlusTarih) }}
              </span>
            </b-col>
          </b-row>
          <b-collapse :id="`collapse-${detay.id}`">
            <b-card>
              <div class="p-2 bg-light-secondary rounded">
                {{ detay.sAciklama }}
              </div>
              <b-img
                @click="showModal(detay.id)"
                class="mt-2"
                height="200"
                width="400"
                v-if="detay.sDosyaUrl"
                :src="detay.sDosyaUrl"
              />
            </b-card>
            <b-modal
              :ref="`modalimage${detay.id}`"
              title="Resim"
              ok-title="Tamam"
              cancel-title="Kapat"
              cancel-variant="outline-secondary"
              no-close-on-backdrop
              centered
              size="lg"
              v-if="detay.sDosyaUrl"
            >
              <b-img
                class="mt-2 custom-support-img"
                v-if="detay.sDosyaUrl"
                :src="detay.sDosyaUrl"
              />
            </b-modal>
          </b-collapse>
        </b-card>
        <b-button v-if="ticketDetayList[0].ticket.isActive" v-b-toggle="'collapse-cevap'">{{ $t('Yeni Cevap Ekle') }} </b-button>
        <b-collapse id="collapse-cevap">
          <b-row class="mt-2">
            <b-col cols="12" md="6" lg="6">
              <b-form-group :label="$t('Açıklama')" label-for="sAciklama">
                <b-form-textarea
                  id="textarea-sAciklama"
                  :placeholder="$t('Açıklama')"
                  v-model="cevap.sAciklama"
                  rows="4"
                />
                <b-button :disabled="$store.getters.getLoader" v-b-toggle="'collapse-cevap'" @click="save" class="mt-2" variant="warning">{{ $t('Gönder') }}</b-button>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="4">
              <label for="file">{{ $t('Dosya') }}</label>
              <b-form-file v-model="cevap.file" id="file" ref="file-input" accept="image/*" />
            </b-col>
          </b-row>
        </b-collapse>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { formatDateToDMYT } from '@core/utils/filter';
import StaticOptions from '@/common/options/StaticOptions';
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  data() {
    return {
      ticket_Id: 0,
      ilgiliDetayPersonel: {},
      musteriDetayKullanici: {},
      ticketDetayList: [],
      cevap: {
        sAciklama: '',
        file: null,
      },
      form: new FormData(),
    }
  },

  methods: {
    save() {
      if (this.cevap.sAciklama === '') {
        AlertService.error(this, 'Açıklama giriniz.')
        return;
      }
      this.form.set('ticketId', this.ticket_Id);
      this.form.set('sAciklama', this.cevap.sAciklama);
      this.form.set('file', this.cevap.file);
      this.$store.dispatch('ticketYonetim/sendAnswer', this.form)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })
            this.fetchTicketDetays();
            this.cevap.file = null;
            this.cevap.sAciklama = null;
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
    resolveDepartmanTur(tur) {
      const result = StaticOptions.departmentTuruOptions.find(x => x.value === tur);
      return result ? result.label : ''
    },

    resolveOncelikTur(tur) {
      return tur === 1 ? 'Acil' : 'Normal'
    },
    formatDateToDMYT,
    fetchTicketDetays() {
      const id = this.$route.params.detailId;
      this.$store.dispatch('ticketYonetim/fetchTicketDetaylar', id)
        .then(response => {
          if (response.statusCode === 200) {
            this.ticket_Id = response.result[0].ticket_Id;
            response.result.forEach(detay => {
              detay.isim = detay.ilgiliDetayPersonel_Id ? `${detay.ilgiliDetayPersonel.sAd}  ${detay.ilgiliDetayPersonel.sSoyad}` : `${detay.musteriDetayKullanici.sAd}  ${detay.musteriDetayKullanici.sSoyad}`
            })
            this.ticketDetayList = response.result;
          }
        })
    },
    showModal(detayId) {
      const modal = `modalimage${detayId}`
      this.$refs[modal][0].show()
    },
  },

  mounted() {
    this.fetchTicketDetays();
  },
}
</script>

<style scoped>
:focus {
  outline: 0 !important;
}
.custom-support-img {
  width: 100%;
  height: auto;
}
</style>
