<template>
  <div>
    <b-card no-body class="p-2">
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card>
            <b-card-title>{{ $t('Atama Geçmişi') }}</b-card-title>
            <b-table
              class="position-relative"
              :items="assignedTickets"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="Kayıt bulunamadı"
            >
              <template #cell(dtOlusTarih)="data">
                {{ formatDateToDMYT(data.item.dtOlusTarih) }}
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col v-if="ticketIsActive" class="ml-2" cols="12" md="12" lg="12">
          <b-button v-b-toggle="'collapse-atama'">{{ $t('Yeni Atama') }}</b-button>

          <b-form>
            <b-collapse id="collapse-atama">
              <b-row class="mt-2">
                <b-col cols="12" md="6" lg="6">
                  <b-form-group label="Personel" label-for="IlgiliPersonel_Id">
                    <personel-selection
                      :personelSelect.sync="personelId"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12" md="6" lg="6">
                  <b-form-group :label="$t('Açıklama')" label-for="sAciklama">
                    <b-form-textarea
                      id="textarea-sAciklama"
                      :placeholder="$t('Açıklama')"
                      v-model="atama.sAciklama"
                      rows="4"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="ticketIsActive" class="mt-1">
                <b-col cols="12" md="6" lg="6">
                  <b-form-group>
                    <b-button
                      variant="warning"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      @click="save"
                    >
                      <feather-icon icon="SaveIcon" class="mr-50" size="16" />
                      {{ $t('Ata') }}
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-collapse>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import { formatDateToDMYT } from '@core/utils/filter';
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import { assignTicket, fetchAssignedTickets } from '../useDestek';
import PersonelSelection from '../../selection/PersonelSelection.vue';

export default {
  components: {
    PersonelSelection,
  },
  data() {
    return {
      tableColumns: [
        { key: 'dtOlusTarih', label: 'Ekleme Tarihi', sortable: true },
        { key: 'sIlgiliPersonel', label: 'Ad' },
        { key: 'sAciklama', label: 'Açıklama' },
      ],
      atama: {
        sAciklama: '',
        ilgiliPersonel_Id: 0,
      },
      personelId: 1,
      assignedTickets: [],
      ticketIsActive: false,
      ticketId: 0,
    }
  },
  methods: {
    save() {
      const query = { ticketId: this.ticketId, personelId: this.personelId, sAciklama: this.atama.sAciklama };
      assignTicket(query)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })
            fetchAssignedTickets(this.ticketId)
              .then(ticketResponse => {
                if (response.statusCode === 200) {
                  this.assignedTickets = ticketResponse.result;
                  this.atama.sAciklama = '';
                  this.personelId = 1;
                } else {
                  AlertService.error(this, ticketResponse.message);
                }
              })
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
    formatDateToDMYT,
    fetchAssignedTickets,
    assignTicket,
    fetchTicket(id) {
      if (id !== -1) {
        this.$store.dispatch('ticketYonetim/fetchTicket', id)
          .then(response => {
            if (response.statusCode === 200) {
              this.ticketIsActive = response.result.isActive;
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },
  },

  mounted() {
    this.ticketId = parseInt(this.$route.params.detailId, 10);
    this.fetchTicket(this.ticketId)
    this.fetchAssignedTickets(this.ticketId)
      .then(response => {
        if (response.statusCode === 200) {
          this.assignedTickets = response.result;
        } else {
          AlertService.error(this, response.message);
        }
      })
  },
}
</script>
