<template>
  <b-tabs
    content-class="col-12 col-md-12 mt-12 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Destek Bilgileri') }}</span>
      </template>

      <destek-bilgileri />
    </b-tab>
    <!--/ general tab -->

    <!-- durum tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="MessageSquareIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Destek Mesajları') }}</span>
      </template>

      <destek-detay-list />
    </b-tab>
    <!--/ durum tab -->

    <!-- Gösterim tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LogOutIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Personele Aktarma') }}</span>
      </template>
          <destek-ata />
    </b-tab>
  </b-tabs>
</template>

<script>
import DestekDetayList from './DestekDetailList.vue'
import DestekBilgileri from './DestekBilgileri.vue'
import DestekAta from './DestekAta.vue'

export default {
  components: {
    DestekDetayList,
    DestekAta,
    DestekBilgileri,
  },
}
</script>
