<template>
  <div>
    <b-card no-body class="p-2">
      <!-- User Info: Input Fields -->
      <b-form>
        <div class="d-flex mt-2">
          <feather-icon icon="InboxIcon" size="19" class="text-primary" />
          <h4 class="mb-0 ml-50 text-primary">Destek Bilgileri</h4>
        </div>
        <b-row class="mt-1">
          <!-- Field: Destek No -->
          <b-col cols="12" md="4">
            <b-form-group label="Kayıt No" label-for="sKayitNo">
              <b-form-input id="sKayitNo" v-model="destek.sKayitNo" readonly />
            </b-form-group>
          </b-col>

          <!-- Field: İşyeri Kodu -->
          <b-col cols="12" md="4">
            <b-form-group label="Konu" label-for="sKonu">
              <b-form-input
                id="sKonu"
                v-model="destek.sKonu"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Departman" label-for="departmentTuru">
              <b-form-input
                id="departmentTuru"
                v-model="destek.departmentTuru"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Öncelik" label-for="ticketOncelikTuru">
              <b-form-input
                id="ticketOncelikTuru"
                v-model="destek.ticketOncelikTuru"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Hareket Kodu -->
          <b-col cols="12" md="4">
            <b-form-group label="Uzak Destek Bilgisi" label-for="sUzakDestekBilgisi">
              <b-form-input
                id="sUzakDestekBilgisi"
                v-model="destek.sUzakDestekBilgisi"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Destek Tarih -->
          <b-col cols="12" md="4">
            <b-form-group label="Oluşturma Tarihi" label-for="dtOlusTarih">
              <b-form-input type="text" v-model="destek.dtOlusTarih" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Cloud Kullanıcı Adı" label-for="sDbKullaniciAdi">
              <b-form-input
                id="sDbKullaniciAdi"
                v-model="destek.sDbKullaniciAdi"
                readonly
              />
            </b-form-group>
          </b-col>

        </b-row>

        <!-- Header: Cari Bilgileri -->
        <div class="d-flex mt-2">
          <feather-icon icon="UserIcon" size="19" class="text-primary" />
          <h4 class="mb-0 ml-50 text-primary">{{ $t('İlgili Kişi Bilgileri') }}</h4>
        </div>

        <b-row class="mt-1">
          <!-- Field: Cari Kodu -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="İlgili Kişi" label-for="sIlgiliKisi">
              <b-form-input
                id="sIlgiliKisi"
                v-model="destek.sIlgiliKisi"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Cari Adı -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Telefon" label-for="sTelefon">
              <b-form-input
                id="sTelefon"
                v-model="destek.sTelefon"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Bayi -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="E Posta" label-for="sEPosta">
              <b-form-input
                id="sEPosta"
                v-model="destek.sEPosta"
                readonly
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row v-if="destek.isActive">
          <b-col cols="12" md="6" lg="4">
            <b-button
            variant="danger"
            @click="closeTicket"
            >
            {{ $t('Desteği Kapat') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { formatDateToDMYT, apiToDate } from '@core/utils/filter';
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import StaticOptions from '@/common/options/StaticOptions';

export default {
  data() {
    return {
      destek: {},
    }
  },

  methods: {
    fetchTicket() {
      const paramId = this.$route.params.detailId;
      if (paramId !== -1) {
        this.$store.dispatch('ticketYonetim/fetchTicket', paramId)
          .then(response => {
            if (response.statusCode === 200) {
              for (const prop in response.result) {
                if (response.result[prop] === 'null') {
                  response.result[prop] = '';
                }
              }
              response.result.dtOlusTarih = apiToDate(response.result.dtOlusTarih);
              response.result.ticketOncelikTuru = this.resolveOncelikTur(response.result.ticketOncelikTuru);
              response.result.departmentTuru = this.resolveDepartmanTur(response.result.departmentTuru);
              this.destek = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },
    resolveDepartmanTur(tur) {
      const result = StaticOptions.departmentTuruOptions.find(x => x.value === tur);
      return result ? result.label : ''
    },

    resolveOncelikTur(tur) {
      return tur === 1 ? 'Acil' : 'Normal'
    },

    closeTicket() {
      const paramId = this.$route.params.detailId;
      AlertService.confirmDelete(this, () => {
        this.$store.dispatch('ticketYonetim/closeTicket', paramId)
          .then(response => {
            if (response.statusCode === 200) {
               this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              });
              this.$router.go(-1);
            } else {
              AlertService.error(this, response.message)
            }
          })
      })
    },
    formatDateToDMYT,
    apiToDate,
  },

  mounted() {
    this.fetchTicket();
  },
}
</script>
